import React, { useContext, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import Terms from '~/components/Terms'
import SEO from '~/components/seo'

const TermsPage = () => {
  const { updateBackground } = useContext(StoreContext)
  const { shopifyShopPolicy } = useStaticQuery(
    graphql`
      query {
        shopifyShopPolicy(type: { eq: "privacyPolicy" }) {
          body
        }
      }
    `
  )

  useEffect(() => {
    // find initial mood asset in shop policy body between {{ ... }}
    const url = shopifyShopPolicy.body
      .split('{{')
      .pop()
      .split('}}')[0]

    const isVideo = url.includes('mp4') ? true : false

    updateBackground({
      video: isVideo,
      asset: url,
    })
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <SEO title="Terms and Conditions" />
      <h1>Terms</h1>
      <Terms />
    </>
  )
}

export default TermsPage
