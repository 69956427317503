import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from './styles'

const Terms = () => {
  const {
    allShopifyShopPolicy: { nodes },
  } = useStaticQuery(
    graphql`
      query {
        allShopifyShopPolicy {
          nodes {
            title
            type
            body
            id
          }
        }
      }
    `
  )
  return (
    <>
      {nodes.map(node => {
        const bodyWithoutUrl = node.body.includes('<p>')
          ? node.body.split('{{')[0].slice(0, -3)
          : node.body
        return (
          <Container key={node.id}>
            <h2>{node.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: bodyWithoutUrl }}></div>
          </Container>
        )
      })}
    </>
  )
}

export default Terms
